// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.subjects-list {
	.red {
		color: red;
		font-weight: bold;
	}
	.tabs-view {
		margin-top: 30px;
		a.active.item {
			background: #647283 !important;
			color: #fff !important;
		}
		.ui.bottom.attached.segment.active.tab {
			background: transparent;
			border: none;
		}
	}
	.ui {
		&.grid {
			> .row {
				padding: 0;
				> .column {
					padding-top: 1rem;
					padding-bottom: 1rem;
				}
			}
		}
		&.table {
			tr {
				&.custom-tr {
					height: 75px !important;
					// height: 96px;
					color: #2e384d !important;
					border-radius: 1px !important;
					background-color: #fff !important;
					.image {
						height: 50px;
						width: 50px;
						margin: 0 auto;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
						border-radius: 100%;
					}
					@media (max-width: 768px) {
						.image {
							height: 150px !important;
							width: 150px !important;
						}
					}
				}
			}

			th,
			td {
				width: auto;

				&:first-child {
					width: 100px;
				}
			}
		}
		&.search {
			width: 100%;

			input {
				&,
				&:focus {
					border-right: none;
				}

				&:focus {
					+ button {
						border-color: $blue;
					}
				}
			}

			button {
				background-color: #fff;
				color: rgba(34, 36, 38, 0.15);
				border: 1px solid rgba(34, 36, 38, 0.15);
				border-left: none;
				border-radius: 0.28571429rem;
				transition: border-color 0.1s ease;
				outline: none;
				pointer-events: none;

				&.active {
					color: $blue;
					cursor: pointer;
					pointer-events: auto;
				}
			}
		}
	}
}
.customCursor {
	cursor: pointer;
}
.session-icon {
	width: 50px;
	height: 50px;
	background: #64728345;
	text-align: center;
	border-radius: 50%;
}
