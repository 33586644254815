// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.task-form {
	.margin-30 {
		margin-top: 30px;
	}
	h2 {
		line-height: 41px;
		font-weight: normal;
		color: $text-color;
	}
	table {
		border-collapse: collapse !important;
		table-layout: fixed !important;
		td {
			border: 1px solid #a8a8a8 !important;
		}
	}
	.field {
		margin-bottom: 20px;
		position: relative;

		&.error {
			label {
				color: red;
			}
			input {
				color: red;
				border-color: red;
				background-color: #fff6f6;
			}
		}
	}
	label {
		margin-bottom: 5px;
		display: block;
		color: #1a1a1a;
		font-size: 11px;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 1.02px;
	}

	.field-wrap + .field-wrap {
		margin-top: 0;
	}
	.field-wrap.date-input-wrap {
		margin-bottom: 15px !important;
	}
	.upload {
		position: relative;
		cursor: pointer;
		height: 2.5rem;
		input {
			opacity: 0;
			position: absolute;
			left: 0;
			right: 0;
			width: 100%;
			top: 0;
			bottom: 0;
			z-index: 9;
		}
		.upload-custom {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			z-index: 5;
			height: 2.5rem;
			padding: 0.5rem 1rem;
			line-height: 1.5;
			color: #555;
			background-color: #fff;
			border: 0.075rem solid #ddd;
			border-radius: 0.25rem;
			box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.upload-custom:before {
			position: absolute;
			top: -0.075rem;
			right: -0.075rem;
			bottom: -0.075rem;
			z-index: 6;
			display: block;
			content: 'Browse';
			height: 2.5rem;
			padding: 0.5rem 1rem;
			line-height: 1.5;
			color: #555;
			background-color: #eee;
			border: 0.075rem solid #ddd;
			border-radius: 0 0.25rem 0.25rem 0;
		}
		.upload-custom:after {
			content: 'Choose file...';
		}
		b {
			position: absolute;
			z-index: 6;
			font-size: 12px;
			color: #727e8e;
			background-color: white;
			top: 1px;
			bottom: 1px;
			left: 13px;
			padding: 6px;
			width: 80%;
			overflow: hidden;
		}
	}
}

.ui.selection.dropdown .icon:not(.delete):not(.close) {
	position: absolute;
	right: 10px;
	z-index: 10;
	color: #0067ac;
	top: 50%;
	transform: translateY(-50%);
	margin: 0;
	padding: 0;
}
