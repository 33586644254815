// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.bookmark-item {
	height: 100%;
	padding: 20px 20px 40px 20px;
	position: relative;
	background-color: #fff;
    color: $gray;
    font-size: 14px;
	border: 1px solid #fff;
	transition: all ease-in-out 0.1s;
	&:hover {
		transform: scale(1.1);
		border: 1px solid $blue;
	}
	h4 {
		color: $dark-gray;
		text-align: center;
    }
    p {
        margin-top: 10px;
    }
	.info-item {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		span {
            color: $dark-gray;
            text-transform: uppercase;
			& + span {
                color: $blue;
                text-transform: none;
			}
		}
	}
	.link {
		position: absolute;
		bottom: 20px;
		right: 20px;
		color: $blue;
		font-size: 28px;
	}
}
