// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.tag-list {
	h2 {
		line-height: 41px;
		font-weight: normal;
		color: $text-color;
		color: #2e384d;
		font-size: 28px;
	}
	h3 {
		line-height: 31px;
		font-weight: normal;
		color: $text-color;
		color: #2e384d;
		font-size: 18px;
	}
	span {
		text-align: center;
		display: block;
	}
	.pagination-wrap {
		margin-top: 25px;
	}
	.delete-btn {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity 0.5s linear;
		position: absolute;
		top: 8px;
		right: 22px;
		cursor: pointer;
		color: #ee1e92;
		border: 1px solid #aeaeae;
		border-radius: 30px;
		background: #f3f6fb;
		font-size: 18px;
		font-weight: 700;
		line-height: 0;
		padding: 11px 6px;
	}

	.custom-column-div:hover {
		.delete-btn {
			visibility: visible;
  			opacity: 1;
		}
	}

	.custom-column-div {
		width: auto !important;
		min-width: 20%;
	}
}
