// Variables
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.archive-inputs {
  .ui.table {
    thead {
      th {
        background-color: #19bd9c!important;
        color: white!important;
        font-weight: 900!important;
        font-size: 14px!important;
        text-transform: capitalize!important;
        white-space: nowrap;
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid #999!important;
        }
      }
    }
  }
}