// Variables
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.list-outcomes {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		&.outcome-item.obs-outcome-item {
			display: grid;
			grid-template-columns: 50px 1fr 200px 95px;
			align-items: center;

			.outcome-list-content, .outcome-learning-status {
				position: relative;
				padding-right: 90px;

				p {
					line-height: 1.45;
					margin: 0;
				}

				.bi-chevron-compact-right {
					position: absolute;
					right: 0;
					top: 0;
					z-index: 1;
					height: calc(100% + 64px);
					width: 150px;
					margin-top: -32px;
				}
			}

			.outcome-learning-status {
				z-index: 2;
				.view-learning-btn {
					&.outcome-learning-status_viewed {
						cursor: pointer;
					}

					&.outcome-learning-status_not-viewed {
						color: #b0bac9;
					}

					&.outcome-learning-status_start-learning {
						color: orange;
						cursor: pointer;
						.fa-fade {
							fill: rgb(255, 149, 0);
							animation-delay: 0s;
							animation-direction: normal;
							animation-duration: 1s;
							animation-iteration-count: infinite;
							animation-timing-function: cubic-bezier(.4,0,.6,1);
							animation-name: fa-fade;
						}
					}

					display: grid;
					grid-template-columns: auto auto 1fr;
					gap: 15px;
					align-items: center;
					background: none;
					border: none;
					text-align: left;
					color: green;
					font-weight: 400;
					font-size: 15px;
					line-height: 22px;
					font-family: Quicksand, sans-serif;
					transform: translateX(-40px);
					position: relative;
					z-index: 2;
					.margin-props {
						margin-right: 7px;
						fill: #0067ac;
					}
				}
			}

			.outcome-learning-stats {
				color: #b0bac9;
				padding-right: 50px;
				white-space: nowrap;
				.outcome-learning-stats_started {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;
					color: green;
				}
			}
		}

		&:not(.outcome-wrap) {
			display: flex;
			justify-content: space-between;

			+ li {
				margin-top: 14px;
			}

			p {
				flex: 1 1 auto;
			}

			.circle-status {
				flex: 0 1 20px;
				min-width: 20px;
				margin-left: 15px;
			}
		}
	}
	&.checkbox {
		&.checked {
			label::before {
				background-color: $blue;
				border-color: $blue;
			}

			label::after {
				color: #fff;
			}
		}
	}
	h2 {
		margin: 0;
		font-size: 28px;
		font-weight: normal;
		line-height: 48px;
	}

	.outcome-wrap {
		&.open {
			.outcome-info {
				display: block;

				.no-answer {
					margin-bottom: 30px;
				}
			}
		}
	}

	.outcome-item {
		margin-top: 8px;
		padding: 16px 20px;
		display: flex;
		background-color: #fff;
		color: $text-color;
		border: 1px solid rgba(46, 91, 255, 0.08);
		border-radius: 1px;

		&.title {
			margin-top: 0;
			padding: 0 20px;
			background-color: transparent;
			border: none;

			.status {
				text-align: left;
			}
		}

		.actions {
			flex: 0 0 60px;
		}

		.ref {
			flex: 0 0 50px;
		}

		.name {
			flex-grow: 1;
		}

		.status {
			display: flex;
			flex: 0 0 15px;
			text-align: center;
			color: $light-gray;

			i {
				font-size: 18px;
				margin-left: 10px;

				@include media {
					//display: none;
				}
			}
		}

		.custom-label {
			color: #8798ad;
			font-size: 12px;
		}

		.circle-status {
			width: 17px;
			height: 17px;
		}

		.view {
			margin-left: 20px;
			color: #8798ad;
			font-size: 20px;

			i {
				vertical-align: top;
			}

			&.done {
				color: $green;
			}
		}

		.ui {
			&.checkbox {
				&.checked {
					label::before {
						background-color: $blue;
						border-color: $blue;
					}

					label::after {
						color: #fff;
					}
				}
			}
		}
	}

	.outcome-info {
		display: none;
		border: 1px solid rgba(46, 91, 255, 0.08);
		border-radius: 1px;
		background-color: #fff;
		padding: 40px 45px;

		.evidence-item {
			+ .evidence-item {
				margin-top: 20px;
			}

			.icon {
				display: inline-block;
				vertical-align: text-top;
				font-size: 20px;
				color: #8798ad;
				margin-right: 10px;
			}
		}

		@include media {
			padding: 15px 20px;
		}
		.answer-status {
			display: inline;
			float: right;
			margin-right: 115px;
			margin-left: 10px;
		}
		.answer-status:hover {
			position: relative;
		}
		.answer-icon {
			margin-left: 50px;
			margin-right: 25px;
		}
		.answer-icon:hover {
			position: relative;
		}
		.answer-label {
			display: inline;
			font-size: 10px;
			float: right;
			text-transform: none;
			margin-left: 20px;
		}
	}

	.info-item {
		+ .info-item {
			margin-top: 25px;
		}

		.add_evidence {
			margin: 15px 0 10px;
		}

		.learning-link {
			margin-left: 15px;
		}

		h5 {
			.blue-text {
				text-transform: none;
			}
			i {
				color: white;
			}
		}
	}

	h5 {
		font-family: $font-main;
		font-size: 12px;
		line-height: 1.15;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 1.12px;
		margin-bottom: 12px !important;

		.icon {
			display: inline-block;
			width: 14px;
			height: 14px;
			text-align: center;
			color: #fff;
			background-color: $blue;
			margin-right: 8px;
		}

		i {
			vertical-align: top;
			font-size: 16px;
			line-height: 0.875;
		}
	}

	p {
		line-height: 1.45;
		margin: 0;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			&:not(.outcome-wrap) {
				display: flex;
				justify-content: space-between;

				+ li {
					margin-top: 14px;
				}

				p {
					flex: 1 1 auto;
				}

				.circle-status {
					flex: 0 1 20px;
					min-width: 20px;
					margin-left: 15px;
				}
			}
		}
	}
}
.cross-reference-modal {
	.outcomeCheck label:before,
	.outcomeCheck label:after {
		width: 20px !important;
		height: 20px !important;
		border: 2px solid #23252559 !important;
		top: -2px !important;
		background-color: #ffffff !important;
	}
	.outcomeCheck label:after {
		left: 0px !important;
		border-radius: 0.21428571rem;
		color: rgba(0, 0, 0, 0.95) !important;
	}
	.customPadding {
		padding: 15px;
		vertical-align: middle;
		display: flex;
		align-items: center;
	}
	.customTooltip {
		position: relative;
		min-width: min-content;
		z-index: auto;
		border: 1px solid #d4d4d5;
		line-height: 1.4285em;
		max-width: 250px;
		background: #fff;
		padding: 0.833em 1em;
		font-weight: 400;
		font-style: normal;
		color: rgba(0, 0, 0, 0.87);
		border-radius: 0.28571429rem;
		box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
		padding-right: 11px !important;
		margin-left: 10px;
	}
	.customTooltip:before {
		position: absolute;
		content: '';
		width: 0.71428571em;
		height: 0.71428571em;
		background: #fff;
		transform: rotate(45deg);
		z-index: 2;
		top: 50%;
		left: -0.30714286em;
		bottom: auto;
		right: auto;
		margin-top: -0.30714286em;
		box-shadow: -1px 1px 0 0 #bababc;
	}

	.ui.checkbox input.hidden+label {
		margin-top: -30px;
	}
	.outcome-status {
		display: inline;
		float: right;
	}
	.column.subject-name-div {
		background: #0067ac;
		padding: 10px;
		margin: 12px;
		color: #ffffff;
	}
}

.outcome-select {
	background: none !important;
    padding: 0 !important;
    margin: 0 !important;
	width: 100% !important;
}

.cross-reference-modal .list-outcomes .outcome-item .name {
    text-align: justify;
}

@keyframes fa-fade {
	50% {
		opacity: .4;
	}
}
