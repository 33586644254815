// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.dashboard-page.manager {
	.info {
		display: flex;
		margin-top: 5px;

		@include media(xs) {
			flex-direction: column;

			> a {
				margin-bottom: 25px;
			}
		}
	}

	.tile {
		background-color: #fff;
		padding: 30px;
		display: block;

		&.over,
		&.attention,
		&.remaining ,
    &.progress {
			color: #1a1a1a;
			text-align: center;
			//height: 100%;

			span {
				display: block;
			}

			.custom-label {
				font-size: 13px;
				font-family: $font-title;
			}
		}
		&.over {
			i {
				color: #b80202;
			}
		}
		&.remaining {
			i {
				color: #ff9900;
			}
		}

		&.progress {
			& i {
				color: $green;
			}
		}

		&.compliance {
			display: flex;
			align-items: center;
			margin-bottom: 1rem;
			color: #1a1a1a;

			span:not(.masked-icon) {
				padding-left: 20px;
				font-size: 25px;
				text-transform: capitalize;
				font-family: $font-title;
			}
			@media all and (min-width:569px) and (max-width: 768px) {
                span:not(.masked-icon) {
					padding-left: 10px;
					font-size: 19px;
               }
            }

			@media all and (min-width:481px) and (max-width: 568px) {
				span:not(.masked-icon) {
					padding-left: 10px;
					font-size: 18px;
				}
			}

			@media all and (min-width:321px) and (max-width: 480px) {
				span:not(.masked-icon) {
					padding-left: 10px;
					font-size: 20px;
				}
			}

			@media all and (min-width:0px) and (max-width: 320px) {
			    span:not(.masked-icon) {
					padding-left: 10px;
					font-size: 12px;
				}
			}

      & i {
				color: $blue;
			}
		}

		&.billing {
			background-color: $blue;
			i,
			span {
				color: white;
			}
		}
	}

	.count {
		font-size: 40px;
		line-height: 55px;
	}

	i {
		font-size: 4em;
	}
}
