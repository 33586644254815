// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.verify {
    margin-top: 30px;
    padding: 40px;
    background-color: #fff;
    p {
        line-height: 28px;
    }
    .ui.button {
        width: 100%;
        max-width: 200px;
        margin-top: 20px;
    }
}