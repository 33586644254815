// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.ui.custom-dropdown.dropdown {
	&.selection {
		border-color: $blue;
		& > .default.text,
		& > .text,
		&.visible .text:not(.default) {
			color: $blue;
		}
		&:hover,
		&:focus,
		&.active,
		&.active:hover,
		&.active .menu,
		&.active:hover .menu {
			border-color: $blue;
		}
		.icon.angle {
			cursor: pointer;
			position: absolute;
			width: auto;
			height: auto;
			line-height: 1.21428571em;
			top: 0.78571429em;
			right: .9em;
			z-index: 3;
			margin: -0.78571429em;
			padding: 0.5em;
			font-size: 18px;
			transition: opacity 0.1s ease;
			color: $blue;
		}
	}
}
