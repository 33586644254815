// Variables
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.outcome-card-wrapper {
    margin-top: 10px;

    .title {
        color: white;
        font-size: 15px;
        font-weight: bold;
        padding: 8px 20px;
        border-radius: 3px;
    }

    .evidence {
        background-color: #72AB0F;
    }

    .viewed {
        background-color: #9D5995;
    }

    .observation {
        background-color: #A2ECF2;
    }


    .outcome-card-body {
        background-color: white;
        padding: 21px;
        .outcome-card-item {
            display: flex;
            margin-bottom: 24px;
            color:#454D60;
            span:first-of-type {
                min-width: 155px;
                display: block;
                font-size: 13px;
                color: #B0BAC9;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}