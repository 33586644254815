// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.customer-logo {
  img {
    display: block;
    height: 40px;
  }

  .ui {
    &.dropdown {
      display: flex;
      align-items: center;

      .menu {
        margin-top: 10px;
      }
    }
  }

  .icon-wrap {
    display: inline-block;
    line-height: 1;
    border: 2px solid $light-gray;
    color: $light-gray;
    padding: 0 3px;
    margin-left: 13px;
  }
}
