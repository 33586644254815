// Variables
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.report-broken-link.ui.vertical.animated.button {
    background-color: transparent;
    width: 146px;
    position: fixed;
    bottom: 20px;
    right: 20px;

    .hidden.content {
        display: block;
        color: black;
        white-space: nowrap;
    }

    &:hover {
        background-color: #0094d1;
        color: white;

        .hidden.content {
            color: inherit;
        }
    }
}

.report-broken-link__feedback {
    padding: 0 20px;
}
