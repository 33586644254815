// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.accessibilityButton {
	padding: 14px 14px 15px;

	span {
		font-size: 15px;
		color: #fff;
		padding-left: 16px;
	}
}

.rootButton {
	outline: none !important;
	border: none !important;
	background: none;
	padding: 0;
}

.accessibilityButton:hover,
.accessibilityButton.active {
	background-color: rgba(46, 56, 77, 0.1);
}
.accessibilityButton.active:before,
.accessibilityButton:hover:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 3px;
	height: 100%;
	background: #bfc5d2;
}

.accessibility-nav-button {
	position: relative;
	top: -7px;
	padding-left: 10px !important;
}
