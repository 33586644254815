// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.manage-outcomes {
	.page-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 20px 0;
		.page-title-text {
			.fa-plus-square {
				color: #69707f;
			}
			.fa-info-circle {
				cursor: pointer;
			}
		}
		> .ui {
			margin-left: 20px;

			@include media(smm) {
				margin-left: 0;
				margin-top: 15px;
			}
		}

		@include media(smm) {
			flex-wrap: wrap;

			> * {
				flex-basis: 100%;
			}
		}
	}

	h2 {
		margin: 0;
		font-size: 28px;
		font-weight: normal;
		line-height: 48px;
	}

	.outcome-wrap {
		&.open {
			.outcome-info {
				display: block;

				.no-answer {
					margin-bottom: 30px;
				}
			}
		}
	}

	.outcome-item {
		margin-top: 8px;
		padding: 0 20px;
		display: flex;
		background-color: #fff;
		color: $text-color;
		border: 1px solid rgba(46, 91, 255, 0.08);
		border-radius: 1px;
		overflow: hidden;

		&.title {
			margin-top: 0;
			padding: 0 20px;
			background-color: transparent;
			border: none;

			.status {
				text-align: left;
			}
		}

		.actions {
			flex: 0 0 60px;
			display: flex;
			align-items: center;
		}

		.ref {
			flex: 0 0 50px;
			display: flex;
			align-items: center;
		}

		.name {
			flex-grow: 1;
			position: relative;
			display: flex;
			align-items: center;
		}

		.status {
			display: flex;
			flex: 0 0 15px;
			text-align: center;
			color: $light-gray;

			i {
				font-size: 18px;
				margin-left: 10px;
				display: flex;
				align-items: center;

				@include media {
					//display: none;
				}
			}
		}

		.custom-label {
			color: #8798ad;
			font-size: 12px;
		}

		.circle-status {
			width: 17px;
			height: 17px;
		}

		.view {
			margin-left: 20px;
			color: #8798ad;
			font-size: 20px;

			i {
				vertical-align: top;
			}

			&.done {
				color: $green;
			}
		}

		.ui {
			&.checkbox {
				&.checked {
					label::before {
						background-color: $blue;
						border-color: $blue;
					}

					label::after {
						color: #fff;
					}
				}
			}
		}
	}

	.outcome-info {
		display: none;
		border: 1px solid rgba(46, 91, 255, 0.08);
		border-radius: 1px;
		background-color: #fff;
		padding: 40px 45px;

		.evidence-item {
			+ .evidence-item {
				margin-top: 20px;
			}

			.icon {
				display: inline-block;
				vertical-align: text-top;
				font-size: 20px;
				color: #8798ad;
				margin-right: 10px;
			}
		}

		@include media {
			padding: 15px 20px;
		}
		.answer-status {
			display: inline;
			float: right;
      margin-right: 115px;
      margin-left: 10px;
		}
		.answer-status:hover {
			position: relative;
		}
		.answer-icon {
			margin-left: 50px;
      margin-right: 25px;
		}
		.answer-icon:hover {
			position: relative;
		}
		.answer-label {
			display: inline;
			font-size: 10px;
			float: right;
			text-transform: none;
			margin-left: 20px;
		}
	}

	.info-item {
		+ .info-item {
			margin-top: 25px;
		}

		.add_evidence {
			margin: 15px 0 10px;
		}

		.learning-link {
			margin-left: 15px;
		}

		h5 {
			.blue-text {
				text-transform: none;
			}
			i {
				color: white;
			}
		}
	}

	h5 {
		font-family: $font-main;
		font-size: 12px;
		line-height: 1.15;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 1.12px;
		margin-bottom: 12px !important;

		.icon {
			display: inline-block;
			width: 14px;
			height: 14px;
			text-align: center;
			color: #fff;
			background-color: $blue;
			margin-right: 8px;
		}

		i {
			vertical-align: top;
			font-size: 16px;
			line-height: 0.875;
		}
	}

	p {
		line-height: 1.45;
		margin: 0;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			&:not(.outcome-wrap) {
				display: flex;
				justify-content: space-between;

				p {
					flex: 1 1 auto;
				}

				.circle-status {
					flex: 0 1 20px;
					min-width: 20px;
					margin-left: 15px;
				}
			}
		}
	}

	.manager-evidence {
		> span {
			margin-right: 8px;
		}
	}

	.manager {
		display: inline-block;
		line-height: 35px;

		.ui.image.photo,
		.photo {
			margin-right: 8px;
		}
	}

	.evidences-bottom-form {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: #fff;

		.container {
			padding-bottom: 0;
			overflow: visible;
			width: auto;

			@include media(md, true) {
				margin-left: 215px;
			}
		}

		.header {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 56px;
			border: solid rgba(46, 91, 255, 0.08);
			border-width: 1px 0;
			background-color: #eaeeff;

			.ui {
				&.button {
					max-width: 180px;
					font-size: 15px;
					font-weight: 600;
					padding: 10px;
					margin-right: 0;

					&.is_open {
						color: $blue;
						border: 1px solid $blue;
						background-color: transparent;
					}

					@include media(400px, true) {
						width: 100%;
					}
				}
			}
		}

		.count-items {
			display: inline-block;
			font-family: $font-main;
			font-weight: 700;
			margin-right: 12px;
		}

		form {
			padding-bottom: 40px;
		}

		.form-fields {
			display: flex;
			margin-top: 30px;

			@include media {
				flex-wrap: wrap;
			}
		}

		.field-wrap {
			min-width: 270px;

			+ .field-wrap {
				flex-grow: 1;

				@include media(sm, true) {
					margin-top: 0;
					margin-left: 20px;
				}
			}

			@include media {
				flex-basis: 100%;
			}
		}

		.ui {
			&.dropdown {
				.default.text {
					color: #647283;
				}
				&.selection {
					border: 1px solid #647283;
					width: 100%;
				}
			}
		}

		.field-label {
			color: #647283;
			font-size: 12px;
			font-weight: 700;
		}

		textarea {
			border-color: #647283;
		}

		.actions {
			margin-top: 10px;

			.ui {
				&.button {
					+ .button {
						@include media(400px, true) {
							margin-left: 20px;
						}

						@include media(400px) {
							margin-top: 10px;
						}
					}

					@include media(400px) {
						width: 100%;
					}
				}
			}

			@include media(400px) {
				margin-top: 30px;
			}
		}
	}

	.evidences-list {
		.evidence-item {
			padding: 17px 25px;
			background-color: #fff;
			transition: background-color 1s 2s ease-out;

			&.flesh {
				background-color: #ffa;
				transition: none;
			}

			+ .evidence-item {
				margin-top: 8px;
			}

			.field-wrap {
				margin: 10px 0 5px;
			}

			button {
				margin: 15px 0;
			}
		}

		.top-items {
			.dark-text,
			.blue-text {
				margin-right: 20px;
			}

			.custom-label {
				margin-right: 10px;
				font-size: 12px;
			}

			i.edit {
				cursor: pointer;
			}
		}

		p {
			line-height: 30px;
		}

		.view-more {
			padding: 0;
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
		}
	}

	.verify {
		margin: 0;
		padding: 35px 32px 40px;

		p {
			line-height: 30px;
		}
	}

	.scroll-top-wrap {
		padding-top: 20px;
	}
}
.outcomeCheck label:before,
.outcomeCheck label:after {
	width: 20px !important;
	height: 20px !important;
	border: 2px solid #23252559 !important;
	top: -2px !important;
	background-color: #ffffff !important;
}
.outcomeCheck label:after {
	left: 0px !important;
	border-radius: 0.21428571rem;
	color: rgba(0, 0, 0, 0.95) !important;
}
.customPadding {
	padding: 15px;
	vertical-align: middle;
	display: flex;
	align-items: center;
}
.customTooltip {
	position: relative;
	min-width: min-content;
	z-index: auto;
	border: 1px solid #d4d4d5;
	line-height: 1.4285em;
	max-width: 250px;
	background: #fff;
	padding: 0.833em 1em;
	font-weight: 400;
	font-style: normal;
	color: rgba(0, 0, 0, 0.87);
	border-radius: 0.28571429rem;
	box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
	padding-right: 11px !important;
	margin-left: 10px;
}
.customTooltip:before {
	position: absolute;
	content: '';
	width: 0.71428571em;
	height: 0.71428571em;
	background: #fff;
	transform: rotate(45deg);
	z-index: 2;
	top: 50%;
	left: -0.30714286em;
	bottom: auto;
	right: auto;
	margin-top: -0.30714286em;
	box-shadow: -1px 1px 0 0 #bababc;
}

.session-evidence-icon {
    display: inline;
    float: right;
}
.status-align{
	display: flex;
}
.learning-asset-align{
	display: flex;
	gap: 8px;
	align-items: center;
	width: 180px;
	padding-right: 40px;
	position: relative;
}
.learning-asset-align-asset{
	display: flex;
	gap: 8px;
	align-items: center;
	width: 130px;
}
.learning-assestment-statuts{
	display: flex;
	.learning-status{
		width: 170px;
		color: #8798ad;
		text-transform: uppercase;
		font-size: 12px;
		display: flex;
		align-items: center;
		letter-spacing: 1.02px;
		line-height: 20px !important;
	}
	.assessment-status{
		width: 130px;
		color: #8798ad;
		text-transform: uppercase;
		font-size: 12px;
		display: flex;
		align-items: center;
		letter-spacing: 1.02px;
		line-height: 20px !important;
	}
}
.learning-asset-icon{
	position: absolute;
	top: -27px;
	right: -60px;
}
.name-card-icon{
	position: absolute;
	top: -37px;
	right: -25px;
}
.flex-assesment{
	display: flex !important;
	gap: 10px !important;;
	align-items: center;
}
.flex-assesment-start{
	display: flex !important;
	gap: 10px !important;
	align-items: center !important;
}
.assesment-icon-size{
	font-size: 28px !important;
}
.textalignstart{
	text-align: start;
}
.textalignend{
	text-align: end;
}
.icon-slide{
	margin-left: 0 !important;
}

@media screen and (max-width: 768px) {
	.hidden-sm, .name-card-icon, .name-card-icon, .learning-asset-icon {
		display: none;
	}
	.learning-asset-align {
		width: 40px;
	}
	.learning-asset-align-asset {
		width: fit-content;
	}
	.learning-status {
		width: 72px;
	}
	.learning-assestment-statuts {
		display: flex;
		flex-direction: column;
		gap: 2px;
		width: 80px;
	}
	.status-align {
		display: flex !important;
		flex-direction: column !important;
		align-items: start !important;
		gap: 15px !important;
		justify-content: center;
		padding: 10px 0;
	}
	.list-outcomes .outcome-item .ref {
		flex: 0 0 30px;
	}
	.list-outcomes .outcome-item {
		padding: 0 10px !important;
	}
	.list-outcomes .outcome-item .ref {
		flex: 0 0 10px;
	}
	.list-outcomes .outcome-item .status i{
		margin-left: 0 !important;
	}
	svg{
		margin-left: 0 !important;
	}
	.li-empty-tag{
		display: none !important;
	}
	.list-outcomes .outcome-item .status {
		flex: 0 0 80px !important;
	}

}
@media screen and (max-width: 1024px) {
	.name-card-icon, .svg-height{
		display: none;
	}
	.list-outcomes .outcome-item .ref {
		flex: 0 0 40px !important;
	}

}
.view-learning-btn{
	color: green !important;
	text-align: start !important;
	padding: 0 !important;
	margin: 0 !important;
	background-color: transparent !important;
	font-weight: 400 !important;
	font-size: 15px !important;
	line-height: 22px !important;
}
.margin-props{
	margin-right: 7px;
}
.start-learning-btn{
	color: orange !important;
	text-align: start !important;
	padding: 0 !important;
	margin: 0 !important;
	background-color: transparent !important;
	font-weight: 400 !important;
	font-size: 15px !important;
	line-height: 22px !important;
}
svg{
	margin-left: 10px;
}
.svg-height{
	height: 140%;
	position: absolute;
	right: 50px;
	top: 10px;
}
.outcome-list-name{
	padding: 20px 40px 20px 0;
	flex-grow: 1;
	position: relative;
}
.outcome-item{
	padding: 0 20px !important;
}