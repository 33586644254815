// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.certificate-item {
  text-align: center;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  background-color: #fff;
  margin-bottom: 2rem;
  overflow: hidden;

  .preview-img {
    height: 150px;
    background: #ccc;
    background-size: cover;
  }

  .info {
    padding: 0 10px 20px 10px;
  }

  .name {
    display: inline-block;
    color: $text-color;
    margin: 15px 0 10px 0;
  }

  .description {
    font-size: 12px;
    color: #606d7c;
    margin-bottom: 4px;
  }

  a {
    display: block;
  }

  .ui {
    &.button {
      &.basic {
        &.blue {
          font-size: 15px;
          color: $blue !important;
          box-shadow: none !important;
        }
      }
    }
  }
}