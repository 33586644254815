// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

#recite-wrapper #zohohc-asap-web-layoutbox {
  height: calc(100% - 60px);
}

#recite-dropdowns #recite-close.show {
  display: block !important;
  cursor: pointer;
}

div#recite-header {
  top: 0 !important;
}

#recite-wrapper * i.fa,
#recite-wrapper * i.fal,
#recite-wrapper * i.fas {
  
  font-family: "Font Awesome 5 Pro" !important;
}