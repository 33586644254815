:global(.ui.modal).confirm {

  > :global(.content) {
    background-color: #fff;
  }
  > :global(.actions) {
    background: #f9fafb;
    padding: 1rem 1rem;
    border-top: 1px solid rgba(34,36,38,.15);
    text-align: right;
  }
}