// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.users-page {
	h2 {
		line-height: 41px;
		font-weight: normal;
		color: $text-color;
	}
	.session-dropdown {
		float: right;
	}
	.ui {
		&.table {
			th,
			td {
				width: auto;

				&:first-child {
					width: 50px;

					@include media {
						margin-bottom: 1rem;
					}
				}
				&:nth-child(2) {
					width: 12%;
				}
				&:nth-child(3) {
					width: 12%;
				}
				&:nth-child(4) {
					width: 15%;
				}
				&:nth-child(5) {
					width: 11%;
				}
				&:nth-child(6) {
					width: 12%;
				}
				&:nth-child(7) {
					width: 12%;
					font-size: 15px;
				}
				&:last-child {
					text-align: center;
				}
			}
		}
	}
}

.users-list .all-topic-table {
	flex-wrap: wrap;
	flex-direction: column;
}
