// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.assessment-instructions {
  .content {
    background-color: #fff;
    padding: 40px 0;
    margin-top: 50px;

    h3 {
      font-family: $font-main;
      font-size: 20px;
      line-height: 1.6;
      font-weight: normal;
    }

    p {
      line-height: 1.85;
    }

    .ui {
      &.grid {
        margin: 0;

        > .column {
          padding: 1rem 50px;

          + .column {
            border-left: 1px solid #caced6;

            @include media {
              border-top: 1px solid #caced6;
              border-left: none;
            }
          }

          @include media {
            padding: 50px;
          }

          @include media(smm) {
            padding: 25px;
          }
        }
      }

      &.button {
        margin: 5px;

        &.secondary {
          display: block;
          width: 100%;
          max-width: 200px;
          margin: 50px auto 0;
        }
      }
    }

    @include media {
      padding: 0;
    }
  }
}
