// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.questions-wrap {
  .content {
    margin-top: 32px;
    padding: 45px 0;
    background-color: #fff;

    .ui {
      &.grid {
        margin: 0;

        > .column {
          padding: 0 45px;

          + .column {
            border-left: 1px solid #caced6;

            @include media {
              border-top: 1px solid #caced6;
              border-left: none;
            }
          }

          @include media {
            padding: 50px;
          }

          @include media(smm) {
            padding: 25px;
          }
        }
      }

      &.checkbox {
        + .checkbox {
          margin-top: 30px;
        }
      }
    }

    .title {
      color: $blue;
      font-size: 20px;
      line-height: 32px;
    }

    .question {
      margin-top: 8px;
      color: $dark-gray;
      font-size: 28px;
      line-height: 48px;
    }

    form {
      margin-top: 20px;

      label {
        text-transform: none;
      }

      @include media {
        margin-top: 0;
      }
    }

    span.label {
      margin-bottom: 20px;
      display: inline-block;
      color: $text-color;
      font-size: 16px;
      line-height: 32px;
    }

    .depends-wrap {
      margin-top: 35px;
      padding-top: 22px;
      border-top: 1px solid #CACED6;

      .title {
        color: $dark-gray;
        font-size: 16px;
        line-height: 32px;
      }

      p {
        margin-bottom: 20px;
        color: $gray;
        line-height: 30px;
      }
    }

    .actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 150px;

      .ui {
        &.button {
          &:last-child {
            margin-right: 0;
          }
        }
      }

      @include media(lg) {
        > * {
          flex: 0 1 100%;
          margin-bottom: .25em;

          &:last-child {
            margin-bottom: 0;
          }
        }

        > div {
          display: flex;

          > * {
            flex: 0 1 50%;
          }

          @include media(xs) {
            flex-wrap: wrap;

            > * {
              flex: 0 1 100%;
              margin-right: 0;
              margin-bottom: .25em;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .custom-radio {
      & + .custom-radio {
        margin-top: 23px;
      }
    }

    .custom-checkbox {
      display: block;
    }

    @include media {
      padding: 0;
    }
  }
}
.outcome-questions-count{
  display: flex;
  flex-direction: column;
}