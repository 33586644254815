// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.users-list {
  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actions {
      button {
        margin-left: 20px;
      }
    }
  }

  .ui {
    &.table {
      th {
        span {
          vertical-align: middle;
        }
      }

      tr {
        &.custom-tr {
          height: 75px;

          &.inactive {
            background-color: rgba(255, 0, 0, .2);
          }
        }
      }
    }

    &.selection {
      &.custom-dropdown {
        min-width: 150px;
        background-color: transparent;
      }
    }

    &.checkbox {
      vertical-align: text-bottom;
    }
  }

  .ui.avatar.image,
  .user-photo {
    margin-right: 10px;
    display: inline-block;
  }

  .users-list-status {
    display: flex;
    align-items: center;

    .circle-status {
      margin-right: 10px;
    }
  }
}

.users-filter {
  form {
    margin-top: 20px;
  }

  .ui {
    &.search {
      width: calc(100% - 28px);

      input {
        &,
        &:focus {
          border-right: none;
        }

        &:focus {
          + button {
            border-color: $blue;
          }
        }
      }

      button {
        background-color: transparent;
        color: rgba(34, 36, 38, 0.15);
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-left: none;
        border-radius: 0.28571429rem;
        transition: border-color 0.1s ease;
        outline: none;
        pointer-events: none;

        &.active {
          color: $blue;
          cursor: pointer;
          pointer-events: auto;
        }
      }
    }

    &.button {
      margin-top: 30px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.customCheck label:before, .customCheck label:after {
  width: 20px!important;
  height: 20px!important;
  border: 2px solid #23252559!important;
  top: -3px !important;
}
.customCheck label:after {
  top: 0px !important;
  left: 1px!important;
  border: 0px !important;
}
