// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.matrix-wrap {
    padding-top: 27px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .button-link {
        padding: 0px 30px;
        cursor: pointer;
        border: none;
        &:hover {
            background-color: #0094d1;
            color: #fff;
        }
    }
    .matrix-header {
        display: flex;
        align-items: flex-start;
        h1 {
            margin-right: 20px;
            color: #0067ac;
        }
    }
    .matrix-filter-wrap {
        display: flex;
        align-items: flex-start;
        button {
          margin-top: 25px;
        }
    }
    .loader-wrap {
        position: absolute;
        z-index: 50;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
    }
}

.training-matrix-intro-cta {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    font-weight: 600;
}
