.confirmationContent {
    padding: 1rem;
}

.confirmationHelperText {
    margin-top: 2rem;
    font-weight: 600;
}

.confirmationActionContainer {
    display: flex;
    gap: 1rem;
}

.confirmationActionContainer > div {
    margin-bottom: 0;
}

.confirmationActionContainer input {
    width: inherit;
}
