// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.subject-user-card {
  white-space: nowrap;
  background-color: #fff;
  margin-right: 30px;
  padding: 15px 24px;

  .photo {
    margin-right: 10px;
  }

  > * {
    vertical-align: middle;
  }
}