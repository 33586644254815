// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

#root {
	.single-user {
		.user-card {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #fff;
			padding: 30px 40px;

			@include media {
				flex-wrap: wrap;

				> div {
					flex-basis: 100%;

					&:first-child {
						margin-bottom: 30px;
					}

					&:nth-child(3) {
						margin-top: 15px;
					}
				}
			}
		}

		.user-name {
			display: block;
			font-size: 20px;
			line-height: 1.6;
			color: $text-color;
			margin-top: 8px;
		}

		.info-item {
			+ .info-item {
				margin-top: 15px;
			}
		}

		.user-account {
			max-width: 110px;
		}

		.custom-label {
			display: block;
		}

		.actions {
			flex: 0 1 100% !important;
			max-width: 180px;

			.button {
				width: 100%;
				margin-right: 0;

				+ .button {
					margin-top: 10px;
				}
			}

			@include media {
				max-width: none;
				margin-top: 30px;
			}
		}

		.ui {
			.button {
				width: 100%;
				max-width: 180px;
				display: block;

				+ .button {
					margin-top: 17px;
				}
			}

			&.user-subjects-table {
				margin-top: 40px;

				tr {
					&.custom-tr {
						@include media {
							height: auto;
						}
					}
				}

				th,
				td {
					@include media(sm, true) {
						&:nth-child(1) {
							width: 30% !important;
						}
						&:nth-child(2) {
							width: 25% !important;
						}
						&:nth-child(3) {
							width: 15% !important;
						}
						&:nth-child(4) {
							width: 25% !important;
						}
						&:nth-child(5) {
							width: 5% !important;
						}
					}

					@include media {
						text-align: center;

						&:first-child {
							font-weight: bold;
							margin-bottom: 0 !important;
						}

						button {
							padding-left: 0;
							padding-right: 0;
						}
					}
				}

				th {
					i {
						&.info {
							vertical-align: middle;
							font-size: 18px;
							padding-left: 5px;
						}
					}

					span {
						vertical-align: middle;
					}

					@include media {
						&:last-child {
							display: none !important;
						}
					}
				}

				td {
					@include media {
						&:nth-child(3) {
							max-width: 200px;
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}
			&.table.user-learning-archive-table {
				tr {
					&.custom-tr {
						height: 55px;
					}

					td:last-child {
						width: 12%;
					}
				}
			}
		}

		.user-learning-archive {
			.header {
				.input {
					background: transparent;
				}
				.icon {
					color: #bfc5d2;
				}
			}
		}

		.user-subjects,
		.user-learning-archive {
			margin-top: 70px;
			overflow-x: scroll;

			.table-sort-toggle {
				&.center {
					margin: auto;
				}
			}
			.tabs-view {
				margin-top: 30px;
				a.active.item {
					background: #647283 !important;
					color: #fff !important;
				}
				.ui.bottom.attached.segment.active.tab {
					min-height: 100px !important;
					background: transparent;
					border: none;
				}
			}
		}

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left-part {
				display: flex;
				align-items: center;
			}

			.link {
				cursor: pointer;
				margin-left: 25px;
				font-size: 16px;
			}
		}

		h3 {
			margin: 0;
			color: $text-color;
			font-size: 20px;
			font-weight: normal;
		}
	}
}

.user-activity {
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.export-btn {
		width: 100%;
		max-width: 95px;
		height: 40px;
		color: $green;
		border: 1px solid $green;
		border-radius: 4px;
		outline: none;
		background-color: transparent;
		transition: all 0.1s;
		cursor: pointer;

		&:hover {
			background-color: $green;
			color: #fff;
		}

		i {
			display: inline-block;
			margin-right: 10px;
		}
	}

	.activity-list {
		list-style: none;
		padding: 0;
		margin: 20px 0 0 0;
	}

	.activity-item {
		display: flex;

		> * {
			flex: 0 1 auto;
		}

		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			width: 32px;
			height: 32px;
			text-align: center;
			line-height: 32px;
			border-radius: 50%;
			background-color: #ccc;
			margin-right: 10px;

			&.auth {
				color: #bfc5d2;
				background-color: #d6dffe;
			}

			&.began {
				color: #26c1d4;
				background-color: #ccf3f7;
			}

			&.viewed {
				color: #8c54ff;
				background-color: #f7d7db;
			}

			&.completed {
				color: $green;
				background-color: #d7efd6;
			}
		}

		.custom-label {
			display: block;
		}
	}
}

.subject-user-card {
	.ui {
		&.image {
			&.photo {
				&.avatar {
					width: 3em;
					height: 3em;
					margin-right: 10px;
				}
			}
		}
	}
}

.subject-status-wrap {
	display: flex;
	justify-content: center;
	align-items: center;

	.circle-status {
		margin-right: 10px;
	}
}

.edit-user-modal {
	.field-wrap {
		margin-top: 23px;
	}
}

.signoff-box {
	h2 {
		margin: 0;
		font-size: 28px;
		font-weight: normal;
		line-height: 48px;
	}
	p {
		padding: 20px;
		background: #fff;
	}
}
