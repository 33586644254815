@import '~Styles/colors';

:global(.ui.grid).root {
  > :global(.row) {
    padding: 0;
    > :global(.column) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}
.item {
  display: flex;
  align-items: center;
  background-color: $charcoal-grey;
  padding: 16px 10px 15px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  transition: background-color .2s;
  &:hover {
    color: #fff;
  }
  span {
    white-space: nowrap;
  }
}
.total_current {
  background-color: #b80202;
}
.total_optional {
  background-color: #0067ac;
}
.total_completed {
  background-color: #238823;
}
:global(i.icon).icon {
  color: inherit;
  font-size: 1.8em;
  position: relative;
  top: -2px;
}
