// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.custom-column-div {
	width: 100% !important;
	border: 2px solid #647382;
	background: #fff;
	text-align: center;
	border-radius: 14px;
	padding: 15px;
	.custom-column {
		display: flex !important;
		flex-direction: column;
		gap: 10px;
	}
	.tag-listing {
		width: fit-content;
		margin: 0;
		padding: 0;
		border: 0;
	}

	h3 {
		font-size: 18px;
		font-weight: bold;
		font-family: 'Quicksand', sans-serif;
		margin-bottom: 0;
	}

	p {
		font-size: 18px;
		word-break: break-all;
	}

	&.pointer {
		cursor: pointer !important;
	}
}

.license-subscription {
	display: flex;
	gap: 8px;
}

.bundle-proceed {
	width: 150px !important;
	height: 35px !important;
	margin-top: -6px !important;
}
