// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.certificates-list {
  .available-certificates {
    margin-top: 55px;
  }

  h2 {
    line-height: 41px;
    font-weight: normal;
    color: $text-color;
  }
}
