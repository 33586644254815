// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.grid-wrap {
    .pickerWrap {
        height: 450px;
    }
    margin: 20px 0;
    height: 100%;
    width: 100%;
    .cell {
        padding: 10px;
        text-align: center;
        transition: 0.3s ease-in-out all;
        display: flex;
        align-items: center;
        justify-content: center;
        .tooltip {
            display: flex;
        }
        .field-wrap.date-input-wrap {
            margin-bottom: -20px !important;

            .ui.popup.left.top {
                margin-bottom: 0;
            }

            .ui.popup {
                td {
                    padding: 0.5em;
                }
                th:last-child {
                    padding-right: 0.5em !important;
                }
                th:first-child {
                    padding-left: 0.5em !important;
                }
                &.popup:before {
                    display: none;
                }
            }
        }
        
        .field-wrap {
            width: 100%;
            &.disabled {
                .link {
                    pointer-events: none;
                }
            }
        }
        &.count-cell {
            justify-content: space-between;
            height: 35px;
            .total {
                background-color: green;
                color: white;
                padding: 5px;
                margin-left: 5px;
                font-size: 1.2em;
            }
        }
        &.count-cell-right {
            justify-content: flex-end;
            height: 35px;
        }
        &.active {
            background-color: #4b71ad !important;
            i {
                color: white;
            }
        }
        i {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 3px;
            font-size: 1.23em;
        }
        &.white {
            background-color: white;
            color: black;
        }
        &.red {
            background-color: #b80202;
            color: white;
        }
        &.green {
            background-color: #238823;
            color: white;
        }
        &.amber {
            background-color: #ff9900;
            color: white;
        }
    }
}
