// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.subject-page {
  font-size: 15px;
  color: $gray;
  flex: 0 1 100%;

  .title {
    p {
      line-height: 28px;
    }
  }

  h1 {
    font-weight: normal;
    color: $text-color;
  }
}

.obs-module-outcomes {
  li {
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
  }
}

.obs-module-outcomes {
  &.obs-module-outcomes--readonly {
    li {
      &:hover {
        text-decoration: underline;
      }
      cursor: pointer;
    }
  }
}

.obs-pagination-container {
  display: 'flex';
  justify-content: 'center';

  .pagination {
    flex-wrap: wrap;

    .item.active  {
      background-color: orange !important;
      color: white !important;
    }

    .item[data-obs-content-viewed="true"] {
      background-color: green;
      color: white;
    }
  }
}

.obs-lesson-feedback_toggle-trigger {
  cursor: pointer;
  user-select: none;
}
