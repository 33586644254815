// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.migrate-banner {
    font-family: "Quicksand", sans-serif;
    background-color: #e4007f;
    color: white;
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: center;
}
.migrate-banner h2 {
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 600;
}
.migrate-banner p {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.6;
}
.migrate-banner a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
}
.migrate-banner a:hover {
    color: #ffcc00;
}
