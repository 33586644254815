// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.topic-list {
	h2 {
		line-height: 41px;
		font-weight: normal;
		color: $text-color;
	}
	.description-table {
		border-collapse: collapse !important;
		table-layout: fixed !important;
		td {
			border: 0px solid #a8a8a8 !important;
			background: #fff !important;
			border-right: 15px solid #f4f6fd !important;
		}
		.spacer {
			height: 15px !important;
		}
		span {
			margin-left: 30px;
		}
	}
}
