// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.section-learn {
	.menu-wrap {
		width: 100%;
		margin: 0;
		padding: 0;
		flex: 0 1 260px;
		background-color: #ebeef6;
		list-style: none;
	}
	.menu-item {
		position: relative;
		&.active,
		&:hover {
			background-color: #fff;
			&::before {
				content: '';
				width: 5px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: $blue;
			}
			.link {
				color: $blue;
			}
		}
	}
	.bookmarks {
		margin-top: 15px;
		padding-left: 30px;
		i {
			margin-right: 10px;
			display: inline-block;
			color: $blue;
			font-size: 24px;
			vertical-align: middle;
		}
		a {
			padding: 15px 0;
			color: $gray;
		}
	}
	.link {
		height: 56px;
		padding-left: 30px;
		display: block;
		color: #69707f;
		line-height: 56px;
		&.active {
			color: $blue;
		}
	}
}
