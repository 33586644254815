// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.users-page {
  .ui {
    &.table {
      th,
      td {
        width: auto;

        &:first-child {
          width: 50px;

          @include media {
            margin-bottom: 1rem;
          }
        }
        &:nth-child(2) {
          width: 30%;

          > * {
            vertical-align: middle;
          }

          @include media(lg) {
            width: 22%;
          }
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 15%;
        }
        &:nth-child(5) {
          width: 15%;
        }
        &:nth-child(6) {
          width: 110px;
        }
        &:nth-child(7) {
          font-size: 20px;

          i {
            vertical-align: middle;
            font-size: 18px;
            padding-left: 5px;
          }
        }
        &:last-child {
          width: 65px;
        }
      }
    }
  }
}