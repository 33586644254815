// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.activity-page {
  .ui {
    &.table {
      thead {
        &.sorting {
          button {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      th,
      td {
        width: auto;

        &:first-child {
          width: 20px;
        }
        &:nth-child(2) {
          width: 25%;

          > * {
            vertical-align: middle;
          }
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 15%;
        }
        &:nth-child(5) {
          width: 100px;
        }
        &:nth-child(6) {
          width: 100px;
        }
        &:nth-child(7) {
          width: 100px;
        }
        &:last-child {
          width: 65px;
        }
      }
    }
  }
}
