// Variables
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.users-list.assessor {
  .ui {
    &.table {
      th,
      td {
        width: auto;

        &:first-child {
          width: 30%;
        }

        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4), &:nth-child(5) {
          width: 11%;
        }
        .user-link {
          display: inline-block;
          cursor: pointer;
        }
      }
    }
  }
}
