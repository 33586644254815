// Variables
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.outcome-labels {
    display: flex;
    margin-bottom: 18px;
    .outcome-label {
        display: block;
        color: white;
        font-size: 15px;
        font-weight: bold;
        border-radius: 3px;
        padding: 8px 20px;
        margin-right: 12px;
        &:first-of-type {
           background-color: #0067AC; 
        }
        &:nth-of-type(2) {
           background-color: #F2C830; 
        }
        &:nth-of-type(3) {
           background-color: #72AB0F;
           margin-right: 0;
        }
    }
}