// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.session-date {
	border: 1px solid rgba(34, 36, 38, 0.15);
	margin-bottom: 15px;
	padding: 8px 15px;
	border-radius: 3px;
}
