@import './variables';

@media only screen and (max-width: #{map-get($responsive-breakpoints, sm)}) {
  .hidden-xs {
    display: none !important;
  }
}

@media only screen and (max-width: #{(map-get($responsive-breakpoints, smm))}) {
  .hidden-smm {
    display: none !important;
  }
}

@media only screen and (max-width: #{(map-get($responsive-breakpoints, sm))}) {
  .hidden-sm {
    display: none !important;
  }
}

@media only screen and (max-width: #{(map-get($responsive-breakpoints, md))}) {
  .hidden-md {
    display: none !important;
  }
}

@media only screen and (max-width: #{map-get($responsive-breakpoints, lg)}) {
  .hidden-lg {
    display: none !important;
  }
}
