// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.ui.modal.small.add-users-modal {
	& > .users-list {
		border-bottom: 1px solid #e0e7ff;
	}
	.group-fields {
		padding-top: 25px;
	}
}
.tag-listing {
	padding: 30px;
	background: #ececec;
	cursor: pointer !important;
	text-align: center;
}
.custom-column {
	padding-left: 0 !important;
	padding-right: 0 !important;
	border: 1px solid #ccc;
	margin-top: -1px !important;
	margin-left: -1px !important;
}

.users-list {
	.field-wrap {
		.ui.selection.dropdown {
			width: 100%;
		}
	}
	.field-wrap + .field-wrap {
		margin-top: 0;
	}
	.field-wrap.date-input-wrap {
		margin-bottom: 0 !important;
	}
}

.warning {
	max-width: 450px;
    text-align: justify;
    display: inline!important;
    margin-left: 20px!important;
    margin-top: 30px!important;
}
