// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.sections-to-export {
  width: 210mm;
}

.single-subject {
  &.scorm-subject {
    .certificate {
      .certificate-item {
        min-width: 350px;
      }
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media(lg) {
      flex-direction: column;
    }
  }

  .ui.basic.button.export-button {
    border: none;
    box-shadow: none;
    color: $blue!important;
    padding: 0;
    margin-top: 10px;

    &:hover, &:focus {
      background-color: transparent!important;
    }

    i {
      margin-left: 5px!important;
      color: $blue;
    }
  }

  .info {
    max-width: 600px;
    flex: 1 1 auto;

    &.flex {
      display: flex;
      align-items: center;

      h1 {
        margin-top: 0;
      }

      @include media {
        flex-wrap: wrap;
      }
    }

    .subject-user-card {
      @include media {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    @include media(lg) {
      max-width: none;
    }
  }

  i {
    padding-left: 4px;

    &.info {
      flex: auto;
    }
  }

  .subject-progress {
    flex: 0 1 30%;
    max-width: 350px;

    @include media(lg) {
      flex-basis: 100%;
      margin-top: 20px;
    }

    @include media {
      max-width: none;
    }
  }

  .actions {
    margin-top: 20px;

    .button-link {
      @include media {
        display: block;
      }
    }
  }

  .ui {
    &.table {
      &:not(.unstackable) {
        tr {
          > th,
          > td {
            &:not(.mobile-cell-hide) {
              @include media {
                display: flex !important;
                justify-content: space-between;
              }
            }
          }
        }
      }

      th {
        font-weight: initial !important;
      }

      tr {
        &.custom-tr {
          @include media {
            height: auto;
          }
        }
      }

      .mobile-cell-data {
        text-align: right;

        .link {
          display: block;
        }

        @include media(sm, true) {
          display: none;
        }
      }

      .mobile-cell-hide {
        @include media {
          display: none !important;
        }
      }
    }

    &.sections-table {
      margin-top: 35px;

      td:first-child {
        width: 60%;
      }
    }
  }

  .button-link {
    margin-right: 20px;
    display: inline-block;
  }

  .link {
    color: $blue;
    cursor: pointer;
  }

  .edit_end_date {
    background-color: transparent;
    color: $blue;
    border: none;
    cursor: pointer;
    outline: none;
  }
}
