// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.outcome-content {
	width: 100%;
	padding: 35px 10px 35px 75px;
	flex: 1 1 auto;
	background-color: #fff;
	.content-item {
		max-width: 90%;
		position: relative;
		& + .content-item {
			margin-top: 35px;
			padding-top: 35px;
			border-top: 1px solid #BFC5D2;
		}
		&:first-of-type {
			.bookmark {
				top: 5px;
			}
		}
		p {
			max-width: 90%;
		}
		.bookmark {
			position: absolute;
			top: 40px;
			right: 0;
			background-color: transparent;
			color: $blue;
			font-size: 26px;
			border: none;
			cursor: pointer;
			outline: none;
		}
	}
	.actions {
		margin-top: 85px;
		display: flex;
		justify-content: space-between;
		.button-link {
			width: 100%;
			max-width: 200px;
		}
	}
}