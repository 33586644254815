.listItem {
    display: grid !important;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    column-gap: 10px;
    align-items: center;
}

.checkbox {
    grid-row: 1 / 3 span;
}

.actionPositive {
    color: white;
    i {
        color: currentColor !important;
    }
}

@media (max-width: 500px) {
    .listHeader {
        font-size: medium;
    }
}

@media (min-width: 600px) {
    .listItem {
        grid-template-columns: auto auto 1fr;
        grid-template-rows: auto;
    }

    .checkbox {
        grid-row: initial;
    }
}