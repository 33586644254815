// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.user-info-form {
	width: 100%;
	max-width: 600px;
	margin-top: 40px;
	padding: 30px;
	background-color: #fff;
	.actions {
		text-align: right;
	}
	.ui.button {
		margin-top: 20px;
	}
}

.user-info-form__actions {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
