// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.subjects {
    font-family: $font-main;
    .row {
        display: flex;
        padding: 8px 0;
        align-items: center;

        @include media(smm) {
            flex-direction: column;
        }

        .cell {
            flex: 0 0 30%;
            padding: 0 10px;
            vertical-align: middle;
            &.subject-column {
                flex: 0 0 34%;
                color: #1a1a1a;
            }
            &.checkbox-column {
                flex: 0 0 6%;
                padding-top: 3px;
            }
            .dropdown {
                min-width: auto;
            }

            .field-wrap {
                margin-bottom: 0;
            }
        }
    }
    .header {
        padding: 8px 13px;
        .cell {
            font-size: 12px;
        }
    }
    .body {
        background-color: #F4F6FC;
        border-radius: 4px;
        border: 1px solid #E0E7FF;
        padding: 18px 13px;
        .row {
            border-bottom: 1px solid #E0E7FF;
            &:last-child {
                border-bottom: none;
            }
            .cell {
                @include media(smm) {
                    padding-bottom: 10px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
    input, .dropdown {
        min-width: auto;
        min-height: auto;
        width: 100%;
        border-radius: 0;
        height: 34px;
        border: 1px solid #DFE7FF;
        padding-left: 7px;
    }
    .date-input-wrap {
        font-size: 12px;
        i {
            font-size: 14px;
            background-color: #EAEAEA;
            opacity: 1;
            border-radius: 0;
            border: 1px solid #DFE7FF;
        }
    }
}
