// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.app-notifications {
	padding: 5px 10px;
	text-align: center;
	&.error {
		background-color: red;
		color: #fff;
		font-weight: 600;
	}
}

.refresh-button {
	background: none;
	border: none;
	text-decoration: underline;
	cursor: pointer;
	color: black;
	transition: color .2s ease-in-out;

	&:hover {
		color: var(--zohohc_asap_web_appziausermessage_bg);
	}
}
