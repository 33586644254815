// Variables
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.archive-info {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    padding-right: 30%;
}

.info-row {
    display: flex;
    min-width: calc(50% - 20px);
    margin-bottom: 18px;
    margin-right: 20px;
    span:first-of-type {
        min-width: 140px;
        font-size: 13px;
        color: #B0BAC9;
        text-transform: uppercase;
        font-weight: bold;
    }
    span {
        font-size: 15px;
        color: #454D60;
    }
}