// Variables
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.archive-outcome {
    border-bottom: 1px solid #0067AC;
    margin-bottom: 34px;
    .outcome-card {
        background-color: white;
        padding: 21px;
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 30px;
        }
        .outcome-card-item {
            display: flex;
            margin-bottom: 24px;
            color:#454D60;
            .card-item-list {
                list-style-type: none;
                padding: 0;
                margin: 0;
                font-size: 15px;
                line-height: 22px;
                counter-reset:  item;
                li.bold {
                    font-weight: bold;
                }
                li::before {
                    content: counter(item) ') ';
                    counter-increment: item;
                }
            }
            span:first-of-type {
                min-width: 155px;
                display: block;
                font-size: 13px;
                color: #B0BAC9;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
}