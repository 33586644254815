// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.section-learn {
	height: 100%;
	margin-top: 20px;
	.back-link {
		i {
			margin-right: 8px;
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.outcomes-wrap {
	padding-top: 20px;
	height: 100%;
	display: flex;
	iframe {
		border: none;
		height: 100%;
	}
}