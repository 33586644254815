// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.tag-list {
	.tabs-view {
		margin-top: 30px;
		a.active.item {
			background: #647283 !important;
			color: #fff !important;
		}
		.ui.bottom.attached.segment.active.tab {
			min-height: 100px !important;
			background: transparent;
			border: none;
		}
	}
	.msg-line {
		text-align: center;
		div {
			font-size: 18px;
			display: inline;
		}
		i {
			font-size: 18px;
		}
	}
	.ui {
		&.search {
			button {
				background-color: #fff;
				color: rgba(34, 36, 38, 0.15);
				border: 1px solid rgba(34, 36, 38, 0.15);
				border-left: none;
				border-radius: 0.28571429rem;
				transition: border-color 0.1s ease;
				outline: none;
				pointer-events: none;
				&.active {
					color: #0067ac;
					cursor: pointer;
					pointer-events: auto;
				}
			}
			input {
				border-right: none;
				&:focus {
					border-right: none;
					+ button {
						border-color: #0067ac;
					}
				}
			}
		}
	}
}
