// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.colour-tooltip {
	.colour-item {
		display: flex;
		align-items: center;
		margin-top: 8px;
		.colour-block {
			height: 25px;
			width: 25px;
			border-radius: 50%;
			margin-right: 8px;
		}
	}
}
