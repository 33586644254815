// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.progress-bar {
  width: 100%;

  + .progress-bar {
    margin-top: 18px;
  }

  .label-bar {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.12px;
    white-space: nowrap;
    color: #8798ad;
  }

  .bar {
    width: 100%;
    height: 16px;
    background-color: #fff;
    overflow: hidden;
  }

  .progress {
    height: 100%;
  }
}
.flex-title{
  display: flex;
  gap: 10px;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 10px;
}
.margin-top-props{
  margin-top: 10px;
}