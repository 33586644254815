// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

#root {
  .ui {
    &.table {
      background-color: transparent;
      border: none;

      .spacer {
        height: 10px;

        &:first-child {
          display: none;
        }
      }

      .link {
        color: $blue;
      }

      thead {
        th {
          background-color: transparent;
          color: #1a1a1a;
          font-family: sans-serif;
          font-size: 12px;
          font-weight: normal;
          text-transform: uppercase;
          letter-spacing: 1.12px;
          border: none;

          > div {
            display: flex;
          }

          &.pointer {
            cursor: pointer;
          }
        }
      }

      tr {
        &.custom-tr {
          height: 96px;
          color: $text-color;
          border-radius: 1px;
          background-color: #fff;

          @include media {
            height: auto !important;
          }
        }
      }

      th,
      td {
        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }

        > * {
          vertical-align: middle;
        }

        .icon {
          &:last-child {
            margin-right: 0;
          }
        }
      }

      td {
        border: none;
      }
    }
  }
}

.table-sort-toggle {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.12px;
  color: #1a1a1a;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  > span {
    display: block;
    margin-top: 3px;
  }

  .icon {
    display: block;
    height: auto;
    font-size: 16px;
    margin-right: 0;
  }
}
