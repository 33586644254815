// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.session-details-list {
	h2 {
		line-height: 41px;
		font-weight: normal;
		color: $text-color;
	}
	.description-table {
		border-collapse: collapse !important;
		table-layout: fixed !important;
		td {
			// border: 1px solid #a8a8a8 !important;
			border: 0px solid #a8a8a8 !important;
			background: #fff !important;
			border-right: 15px solid #f4f6fd !important;
		}
		.spacer {
			height: 15px !important;
		}
		span {
			margin-left: 30px;
		}
	}
	.table-session {
		width: 100%;
		margin-bottom: 1rem;
		color: #212529;
		border-collapse: collapse;
		thead {
			th {
				vertical-align: bottom;
				border-bottom: 2px solid #a8a8a8;
			}
		}
		th {
			padding: 0.75rem;
		}
		td {
			padding: 0.75rem;
			vertical-align: top;
			border-top: 1px solid #a8a8a8;
			text-align: center;
		}
	}
	.table-bordered {
		border: 1px solid #a8a8a8;
		thead {
			th {
				border-bottom-width: 2px;
			}
		}
		th {
			border: 1px solid #a8a8a8;
		}
		td {
			border: 1px solid #a8a8a8;
		}
	}
}
.session-learners-list {
	margin-top: 20px;
	padding: 10px;
}
.session-topic-loader {
	position: absolute !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	right: 50% !important;
}