// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.tags-listing {
	h2 {
		line-height: 41px;
		font-weight: normal;
		color: $text-color;
		color: #2e384d;
		font-size: 28px;
	}
	h3 {
		line-height: 31px;
		font-weight: normal;
		color: $text-color;
		color: #2e384d;
		font-size: 18px;
	}
	span {
		text-align: center;
		display: block;
	}
	.tabs-view {
		margin-top: 30px;
		a.active.item {
			background: #647283 !important;
			color: #fff !important;
		}
		.ui.bottom.attached.segment.active.tab {
			min-height: 100px !important;
			background: transparent;
			border: none;
		}
	}
	.msg-line {
		line-height: 32px;
		text-align: center;
		i {
			font-size: 23px;
		}
		div {
			display: inline;
			text-align: center;
			font-size: 16px;
		}
	}
}
