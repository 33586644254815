// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

@mixin user-cart-media {
  flex-direction: column-reverse;
  align-items: center;
  padding: 20px 25px;

  .right {
    margin-bottom: 20px;
  }
}

@mixin subject-card-media {
  flex-direction: column-reverse;
  max-height: none;

  .info {
    padding: 20px 25px;
  }

  .bg {
    max-width: 100%;
  }
}

.development-spacer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  i {
    font-size: 35px;
    padding-right: 15px;
  }
  h2 {
    margin: 0;
    color: $text-color;
    font-weight: 400;
  }
}

.bottom-cart {
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: $text-color;
  }
  i {
    padding-right: 15px;
  }
}

.dashboard-page.learner {
  .user-cart {
    display: flex;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
    padding: 40px 45px;

    .button-link {
      margin-top: 5px;
    }

    @include media((md, lg)) {
      @include user-cart-media;
    }

    @include media(sm) {
      @include user-cart-media;
    }
  }

  .info-item {
    & + .info-item {
      margin-top: 15px;
    }

    span {
      display: block;
    }
  }

  .right {
    width: 180px;

    .manager-photo {
      margin-bottom: 10px;
      display: inline-block;
    }

    span {
      display: block;
    }
  }

  .ui {
    &.grid {
      .ui {
        &.button {
          width: 100%;
          font-family: $font-main;
          font-size: 15px;
          margin-top: 15px;
        }
      }
    }
  }

  .button-link {
    width: 100%;
  }
}

.dashboard-subject-card {
  display: flex;
  max-height: 302px;
  height: 100%;
  justify-content: space-between;
  background-color: #fff;

  &.empty-card {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 40px;

    i {
      font-size: 80px;
    }
  }

  .info {
    padding: 30px 40px;
  }

  .bg {
    max-width: 50%;
    flex: 0 1 100%;
    background-color: $light-gray;
    background-position: center;
    background-size: cover;
  }

  @include media((md, lg)) {
    @include subject-card-media;
  }

  @include media(sm) {
    @include subject-card-media;

    .bg {
      min-height: 300px;
    }
  }
}