// Variables
@import 'variables';
@import 'colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

// Fonts
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,300i,400,400i,600,600i,700,700i&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,700,700i&subset=cyrillic');
// Normalize
@import 'normalize/variables';
@import 'normalize/vertical-rhythm';
@import 'normalize/normalize-mixin';

@include normalize();

// font awesome
@import '../assets/FontAwesome/scss/light.scss';
@import '../assets/FontAwesome/scss/regular.scss';
@import '../assets/FontAwesome/scss/solid.scss';
@import '../assets/FontAwesome/scss/fontawesome.scss';

@import './grid';
@import './mixins';

body,
html {
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  min-width: 0;
  font-size: 15px;
  background-color: #f4f6fc !important;

  @include media {
    font-size: 14px;
  }

  &[style*="top: 60px"] {
    margin-top: -60px;
  }
}

h1, h2, h3, .ui.modal>.header {
  font-family: $font-title;
}

#recite-wrapper {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: $font-main;

  #recite-wrapper & {
    padding-top: 60px;
  }

  svg {
    overflow: visible;
  }

  img {
    max-width: 100%;
  }
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.blue-text {
  color: $blue;

  &:hover {
    color: $blue;
  }
}

.dark-text {
  color: $text-color;
}

.custom-label,
.field-label,
.field-wrap label {
  color: #1a1a1a;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.02px;
}

.page-title {
  h1 {
    color: #2e384d;
    font-size: 28px;
    font-weight: normal;
    line-height: 41px;
  }

  p {
    line-height: 28px;
  }
}

.ui {
  &.input {
    width: 100%;

    &.error > input {
      color: red;
      border-color: red;
    }

    & > input {
      &:focus {
        border-color: $blue;
      }
    }
  }

  &.selection {
    &.dropdown {
      border: 1px solid #e0e7ff;

      &.loading {
        > i {
          &.icon {
            padding: 0 !important;
          }
        }
      }

      &:hover {
        border: 1px solid #e0e7ff;
      }

      &:focus,
      &.active,
      &.active .menu,
      &.active:hover,
      &.active:hover .menu {
        border: 1px solid $blue !important;
      }

      .item {
        font-family: $font-main;
      }

      .icon {
        &:not(.delete):not(.close) {
          position: absolute;
          right: 10px;
          z-index: 10;
          color: $blue;
        }
      }
    }
  }

  &.button {
    background-color: $blue;
    color: #fff;
    font-family: $font-main;

    &:hover,
    &:focus {
      background-color: #0094d1;
      color: #fff;
    }

    &.secondary {
      background-color: $green;

      &:hover {
        background-color: #2a9426;
      }
    }

    &.reverse {
      background-color: $lightsteelblue;
      color: #fff;
      font-weight: normal;

      &:hover {
        background-color: #6d7d9e;
      }
    }
  }

  &.button:disabled,
  &.buttons .disabled.button,
  &.disabled.active.button,
  &.disabled.button,
  &.disabled.button:hover {
    opacity: 1 !important;
    background: $light-gray;
  }

  .radio {
    &.checkbox {
      line-height: 33px;

      &.custom-checkbox {
        line-height: 24px;

        .box:before,
        label:before {
          width: 24px;
          height: 24px;
        }

        .box:after,
        label:after {
          width: 24px;
          height: 24px;
          font-size: 16px;
        }

        input:checked ~ .box:after,
        input:checked ~ label:after,
        input:checked:focus ~ .box:after,
        input:checked:focus ~ label:after {
          color: $blue;
        }

        .box:before,
        label:before,
        .box:hover:before,
        label:hover:before,
        .box:active:before,
        label:active:before,
        input[type='checkbox']:focus ~ label:before,
        input[type='checkbox']:focus ~ .box:before,
        input:checked ~ .box:before,
        input:checked ~ label:before,
        input:focus ~ .box:before,
        input:focus ~ label:before,
        input:checked:focus ~ .box:before,
        input:checked:focus ~ label:before {
          border-color: $blue;
        }

        label {
          padding-left: 2.5em;
        }

        input[type='checkbox'] {
          width: 24px;
          height: 24px;
        }
      }

      .box:before,
      label:before {
        width: 33px;
        height: 33px;
      }

      .box:after,
      label:after {
        width: 33px;
        height: 33px;
      }

      label {
        padding-left: 3em;
      }

      input[type='radio'] {
        width: 33px;
        height: 33px;
      }

      input:checked ~ .box:before,
      input:checked ~ label:before {
        border-color: $blue;
      }

      input:checked ~ .box:after,
      input:checked ~ label:after,
      input:focus:checked ~ .box:after,
      input:focus:checked ~ label:after {
        background-color: $blue;
      }
    }
  }
}

i {
  &.far, &.fas, &.fal, &.icon {
    color: $blue;
  }
  &.icon {
    &.far, &.fas, &.fal {
      font-family: 'Font Awesome 5 Pro', Icons;
    }
  }
}
.secondary {
  i {
    &.far, &.fas, &.fal, &.icon {
      color: white;
    }
  }
}

.field-wrap {
  position: relative;

  &.date-input-wrap {
    .ui {
      &.popup {
        &.top {
          top: auto !important;
          bottom: 100% !important;
        }
        &.bottom {
          top: 100% !important;
          bottom: auto !important;
        }
        &.left {
          left: 0 !important;
          right: auto !important;
        }
        &.right {
          left: auto !important;
          right: 0 !important;
        }
      }
    }
  }

  + .field-wrap {
    margin-top: 23px;
  }

  .ui {
    &.dropdown {
      &.error {
        &,
        > .text,
        > .default.text {
          background-color: transparent;
          color: red;
          border-color: red;

          i {
            color: red;
          }
        }

        > .menu {
          > .item {
            color: #000;

            &:hover {
              background-color: rgba(0, 0, 0, .1);
            }
          }
        }
      }
    }
  }

  &.error,
  .error.field {
    .field-label,
    .ui.checkbox label,
    label {
      color: red;
    }

    input,
    input:focus,
    textarea,
    textarea:focus,
    .ui.checkbox label::before {
      border-color: red;
    }
  }

  .error.field {
    input {
      &::placeholder {
        color: red;
      }
    }
  }

  .error-msg {
    color: red;
    font-size: 12px;
  }
}

.repeater-field {
  margin-top: 1rem;
  > .field-label {
    margin-bottom: 15px !important;
  }
}
.repeater-field--items {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;

  tr {
    &:first-child {
      .field-wrap {
        margin-top: 0;
      }
      .repeater-field--item--button {
        .icon {
          margin-top: 20px;
        }
      }
    }
    &:not(:last-child) {
      td {
        padding-bottom: 20px;
      }
    }
    .repeater-field--item--button {
      .icon {
        font-size: 16px;
      }
    }
    &.add-item-row {
      button {
        background-color: transparent;
        border: none;
        font-size: 15px;
        color: $blue;
        .text {
          text-decoration: underline;
          vertical-align: middle;
        }
        .plus {
          font-size: 30px;
          vertical-align: middle;
          padding-left: 5px;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }

  td {
    width: auto;
    vertical-align: middle;
    padding: 0 15px 0 0;

    &:last-child {
      padding-right: 0;
    }
  }

  .repeater-field--item--button {
    width: 40px;

    .ui {
      &.button {
        display: block;
        margin-right: 0;
      }
    }
  }
}

.main-content {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;

  .container {
    max-width: none;
    padding-bottom: 50px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}

.container {
  //position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: calc(100vw - 215px);
  padding: 0 40px;

  @media only screen and (max-width: 991px) {
    max-width: 100%;
    padding: 0 15px;
  }
}

.field-wrap {
  .field-label,
  label {
    margin-bottom: 5px;
    display: block;
  }
  margin-bottom: 20px;
}

.error-message {
  margin-top: 5px;
  display: inline-block;
  color: red;
}

.success-message {
  margin-top: 5px;
  display: inline-block;
  color: green;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
}

.button-link {
  padding: 0 20px;
  height: 40px;
  display: inline-block;
  font-family: $font-main;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  background-color: $blue;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #0094d1;
    color: #fff;
  }

  & + .button-link {
    margin-left: 8px;
  }

  &.secondary {
    background-color: $green;

    &:hover {
      background-color: #2a9426;
    }
  }

  &.disable {
    background-color: $light-gray;
    pointer-events: none;
  }
}

a, .link {
  color: $blue;
}

textarea {
  padding: 10px;
  border-color: $blue;
  border-radius: 5px;
  outline: none;
}

.form-modal {
  .actions {
    margin-top: 30px;
  }
  .ui.dropdown.selection {
    border: 1px solid rgba(34,36,38,.15);
    .icon:not(.delete):not(.close) {
      z-index: 10;
    }
  }
}

.modal.confirm {
  .actions {
    .ui.button:not(.primary) {
      background: #e0e1e2 none;
      color: rgba(0,0,0,.6);
    }
  }
}

.create-update-group-modal {
  .actions {
    display: flex;
    justify-content: flex-end;
    button.negative.button {
      justify-self: flex-start;
      margin: 0;
      & + button {
        margin-left: auto;
      }
    }
  }
}

.pagination-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin-top: 10px;
  }
}

label[for="recite-close"],
#recite-close {
  display: none !important;
}

.expand {
  margin-left: 12px;
  padding: 0;
  background-color: transparent;
  font-size: 20px;
  line-height: 1;
  border: none;
  cursor: pointer;
  outline: none;

  i {
    color: #69707f;
    vertical-align: text-top;
  }
}


.ui.multiple.selection.dropdown {
  .labelsWrap {
    display: flex;
    flex-wrap: wrap;
    .ui.label {
      margin-bottom: 5px;
      display: inline-block;
    }
  }
}
.target-tooltip {
	position: absolute;
	left: 134px;
	top: -3px!important;
	z-index: 999999;
}
.target-tooltip-parent {
	position: relative;
	margin-bottom: 40px!important;
}

i.grey.icon {
  color: #ffffff!important;
}