// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.ui.modal.large.add-users-modal {
  & > .header {
    font-size: 28px;
    font-weight: 400;
    border-bottom: none;
    padding-bottom: 0;
  }
  .content {
    padding-top: 15px;
  }
  .repeater-field {
    tr.add-item-row {
      button {
        margin-bottom: 15px;
        background-color: #0067ac;
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .users-list {
    border-bottom: 1px solid #E0E7FF;
  }
  .btn-color{
    color: #ffffff !important;
  }
}
.btn-color{
  color: #ffffff !important;
}
.react-toast-notifications__toast__content{
  overflow: scroll;
  max-height:700px;
}
.session-fields-info {
  margin-top: 6px;
}