// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.circle-status {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #bfc5d2;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;

  &.success {
    background-color: $green;
  }

  &.danger {
    background-color: $orange;
  }

  &.error {
    background-color: $red;
  }
}
