// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.evidences-list-wrap {
	margin-top: 15px;

	background-color: white;
	padding-top: 30px;

	.ui.grid > .row {
		padding-top: initial;
		padding-bottom: initial;

		.column:last-child {
			padding-right: 25px;
		}
	}

	h2 {
		margin: 0;
		margin-left: 25px;
		font-size: 15px;
		font-weight: bold;
	}

	.evidences-list {
		padding-inline-start: 0px;
		.evidence-item {
			min-height: 60px;
			padding: 17px 25px;
			background-color: #fff;
			transition: background-color 1s 2s ease-out;

			&.flesh {
				background-color: #ffa;
				transition: none;
			}

			+ .evidence-item {
				margin-top: 8px;
			}

			.field-wrap {
				margin: 10px 0 5px;
			}

			button {
				margin: 15px 0;
			}
		}

		.top-items {
			.dark-text,
			.blue-text {
				margin-right: 60px;
			}

			.custom-label {
				margin-right: 10px;
				font-size: 12px;
			}

			i.edit {
				cursor: pointer;
			}
		}

		p {
			line-height: 30px;
			margin-top: 15px;
		}
		i.trash.icon {
			margin-left: 20px;
		}
		.view-more {
			padding: 0;
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
		}
	}
}
.clr-black {
	color: black;
	display: inline;
	cursor: pointer;
}
.cursor-pointer {
	cursor: pointer;
}