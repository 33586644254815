// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.group-manager {
  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      .field-wrap {
        margin-right: 20px;
      }

      > .ui {
        &.button {
          margin: 0;
          white-space: nowrap;
        }

        @include media(400px) {
          width: 100%;
          max-width: none;
          margin: 0;

          &.button {
            margin-bottom: 15px;
          }
        }
      }

      @include media(400px) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
      }
    }

    @include media {
      flex-direction: column;
    }
  }

  .ui {
    &.search.fluid {
      max-width: 250px;
      width: 250px;
      margin-right: 10px;
    }
  }

  .nestable-item {
    &.is-dragging {
      &::before {
        border: 1px dashed $gray;
        background-color: $light-gray;
      }
    }

    .nestable-list {
      &::before {
        content: '';
        position: absolute;
        top: 77px;
        left: 20px;
        width: 4px;
        height: calc(100% - 86px);
        background-color: $light-gray;
      }
    }
  }

  .list-header {
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
    color: $light-gray;
    padding: 0 40px 0 20px;
    margin: 35px 0 8px;

    > div {
      &:first-child {
        width: 14px;
      }

      &:nth-child(2) {
        width: 30px;
      }

      &:last-child {
        display: flex;
        flex-grow: 1;
        padding-left: 30px;
      }
    }

    .group-users-count {
      flex-basis: calc(15% + 80px);
    }

    @include media {
      padding: 0 20px;
    }
  }

  .drag-icon {
    color: $light-gray;
    cursor: move;

    i {
      font-size: 20px;
    }
  }

  .group-item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 56px;
    background-color: #fff;
    padding: 0 40px 0 20px;

    .expand-wrapper {
      width: 40px;
      button.expand {
        padding-left: 10px;
      }
    }

    &.not-active {
      opacity: .2;
    }

    @include media {
      padding: 0 20px;
    }
  }

  .group-item-content {
    display: flex;
    flex-grow: 1;
    padding-left: 23px;

    @include media {
      flex-wrap: wrap;
      padding-left: 20px;
    }
  }

  .group-name {
    flex-grow: 1;

    @include media {
      flex-basis: 100%;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  .group-id {
    flex-basis: 15%;
  }

  .group-user {
    flex-basis: 29%;

    @include media {
      flex-grow: 1;
      flex-basis: auto;
    }
  }

  .no-control {
    .group-users-count {
      flex-basis: calc(15% + 80px);
    }
  }

  .group-users-count {
    flex-basis: 15%;
  }

  .edit {
    flex: 0 1 80px;
    font-weight: 600;
    color: $blue;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    span {
      display: inline-block;
      margin-left: 5px;
    }

    @include media {
      flex-basis: auto;
      padding: 0;
    }
  }
  .mo {
       margin-left: 10px !important;
  }
}

.create-update-group-modal {
  .actions {
    text-align: right;
    margin-top: 30px;
  }

  .ui {
    &.loader {
      &::after {
        border-top-color: #767676 !important;
      }
    }
  }
}
