// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.ui.modal.small.add-users-modal {
	& > .users-list {
		border-bottom: 1px solid #e0e7ff;
	}
	.group-fields {
		padding-top: 25px;
	}
}
.tag-listing {
	padding: 10px 16px;
    background: #fff;
    cursor: pointer!important;
    text-align: center;
    border-radius: 14px;
    margin: 14px;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #647382;
}
.custom-column {
	padding-left: 0!important;
    padding-right: 0!important;
    border: 1px solid #ccc;
    margin-top: -1px!important;
    margin-left: -1px!important;
	border: none;
}
