// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.filter-wrap {
    display: flex;
    align-items: flex-start;
    margin: 0 20px 0 0;
    flex-grow: 1;
    flex-wrap: wrap;
    .field-wrap {
        width: calc(16% - 16px);
        margin-top: 0;
        margin-right: 16px;
        margin-bottom: 10px;
        font-size: 12px;
        min-width: 120px;
        flex-grow: 1;
        .field-label {
            color: rgba(0,0,0,.87);
            display: inline-block;
            white-space: nowrap;
        }
        .labelsWrap {
            overflow-y: scroll;
            height: 32px;
            min-height: 32px;
        }
        .search {
            width: 100% !important;
        }
        .clear {
            cursor: pointer;
            right: 5px !important;
        }
        i {
            top: 10px;
        }
        &.small {
            width: calc(8% - 24px);
            min-width: 92px;
        }
        &.day {
            max-width: 92px;
        }
    }
    .field-wrap + .field-wrap {
        margin-top: 0;
    }
    &.is-learner {
        .field-wrap {
            &.day {
                max-width: none;
            }
        }
    }
}