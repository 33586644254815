// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 100%;
    max-width: 275px;
  }

  .logo-wrap {
    display: flex;
    align-items: center;
  }

  .logo {
    width: 172px;
    display: block;
    margin: 0 auto;
  }

  .customer-logo-wrap {
    margin-left: 35px;
  }

  .forms-wrap {
    max-width: 350px;
    margin-top: 65px;
    text-align: center;

    .title {
      color: $blue;
      font-weight: 900;
      font-size: 20px;
      line-height: 41px;
    }

    .description {
      margin-top: 20px;
      line-height: 1.5;
      display: block;
      color: $gray;
      font-size: 16px;
    }

    .bottom-info {
      margin-top: 13px;
      color: $light-gray;
      font-size: 16px;
      text-align: center;

      .link-wrap {
        margin-top: 15px;

        span {
          color: $light-gray;
          font-weight: 400;
        }
      }

      .link {
        color: $blue;
        font-weight: 900;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    form {
      .field-wrap {
        margin-top: 15px;
      }
    }

    button {
      margin-top: 15px;
      border-radius: 10px;
    }
  }

  .ui {
    &.input > input {
      background-color: transparent;
      border: 1px solid $charcoal-grey;
      color: $charcoal-grey;
      text-align: center;
      font-family: $font-main;
      border-radius: 10px;

      &:focus {
        background-color: transparent;
      }

      &::placeholder {
        color: $charcoal-grey;
        opacity: 1;
      }
    }
  }
}