// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.section-info {
  .page-title {
    &.flex {
      display: flex;
      align-items: center;

      h1 {
        margin-top: 0;
        margin-right: 30px;
      }
    }
  }

  .section-progress {
    margin-top: 0.5em;
    padding-top: 0.5em;
    line-height: 28px;
    border-top: 1px solid #CACED6;

    &.manager {
      margin-top: 20px;
      border-top: none;
    }

    span {
      font-weight: 600;
    }
  }


  .section-retake {
    margin-top: 0.5em;
    padding-top: 0.5em;
    line-height: 28px;
    border-top: 1px solid #CACED6;
    font-weight: 600;
    color: #FF0000;
  }

  .actions {
    display: flex;
    align-items: center;
    margin-top: 40px;

    > .icon {
      vertical-align: middle;
      margin: 8px 0 0 8px;
      cursor: pointer;

      @include media(smm) {
        order: 2;
      }
    }

    .button-link {
      flex: 0 1 auto;

      @include media(550px) {
        min-width: 150px;
      }

      @include media(smm) {
        flex-basis: 100%;
        order: 5;
        min-width: 0;

        &:first-child {
          flex-basis: calc(100% - 35px);
          order: 1;
        }
      }
    }

    @include media(smm) {
      flex-wrap: wrap;
    }
  }

  .button-link {
    vertical-align: middle;
    min-width: 200px;
    white-space: nowrap;
    margin-top: 8px;

    &[disabled] {
      opacity: .7;
      cursor: default;

      &.secondary {
        background-color: #33ac2e !important;
      }
    }

    .icon {
      margin-left: 10px;
    }

    @include media(smm) {
      display: block;
      margin-left: 0 !important;
    }
  }
}

.ui {
  &.modal {
    &.instructions-modal {
      > .content {
        font-size: 15px !important;

        p {
          line-height: 1.85;
        }

        @include media {
          padding-top: 40px !important;
        }
      }
    }
  }
}
.mt_25{
  margin-top: 25px!important;
}
.mb_18{
  margin-bottom: -18px!important;
}
.btn-assessment-disable {
  background-color: #7bcf78 !important;
  cursor: none !important;
}
.btn-assessment-enable {
  background-color: #33ac2e !important;
  cursor: pointer !important;
}
.btn-assessment {
  vertical-align: middle;
  min-width: 200px;
  white-space: nowrap;
  margin-top: 8px !important;
  margin-left: 10px !important;
  height: 40px !important;
  padding: 0 20px;
  font-weight: 500 !important;
  background-color: #7bcf78 !important;
  .icon {
    margin-left: 10px;
  }
}

.btn-not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important;
}