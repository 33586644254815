// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.users-list {
  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actions {
      > .ui {
        &.button {
          margin-right: 0;

          @include media(400px, true) {
            margin-left: 10px !important;
          }
        }

        @include media(400px) {
          width: 100%;
          max-width: none;
          margin: 0;

          &.button {
            margin-bottom: 15px;
          }
        }
      }

      @include media(400px) {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
      }
    }

    @include media {
      flex-direction: column;
    }
  }

  .ui {
    &.selection {
      &.custom-dropdown {
        min-width: 150px;
        background-color: transparent;
      }
    }

    &.table {
      tr {
        &.custom-tr {
          height: 75px !important;

          &.inactive {
            background-color: rgba(255, 0, 0, .2) !important;
          }
        }
      }
    }

    &.checkbox {
      vertical-align: text-bottom;
    }
  }

  .ui.avatar.image,
  .user-photo {
    margin-right: 10px;
    display: inline-block;
  }
}

.select-all-popup {
  display: flex;
  flex-direction: column;
  div {
    padding: 5px 0;
  }
}

.users-filter {
  form {
    margin-top: 20px;
  }

  .ui {
    &.button {
      margin-top: 30px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &.search {
      width: calc(100% - 28px);

      input,
      input:focus {
        border-right: none;
      }

      input:focus + button {
        border-color: $blue;
      }

      button {
        background-color: transparent;
        color: rgba(34, 36, 38, 0.15);
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-left: none;
        border-radius: 0.28571429rem;
        transition: border-color 0.1s ease;
        outline: none;
        pointer-events: none;

        &.active {
          color: $blue;
          cursor: pointer;
          pointer-events: auto;
        }
      }
    }
  }
}

.create-user-modal {
  .field-wrap {
    margin-top: 23px;
  }
}
.customCheck label:before, .customCheck label:after {
  width: 20px!important;
  height: 20px!important;
  border: 2px solid #23252559!important;
  top: -3px !important;
}
.customCheck label:after {
  top: -1px !important;
  left: 0px!important;
  border: 0px !important;
}
