// Variables
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.topic-page {
	.ui {
		&.table {
			th,
			td {
				width: auto;

				&:first-child {
					width: 2%;

					@include media {
						margin-bottom: 1rem;
					}
				}
				&:nth-child(2) {
					width: 7%;

					> * {
						vertical-align: middle;
					}

					@include media(lg) {
						width: 50%;
					}
				}
				&:nth-child(3) {
					width: 40%;
				}
				&:nth-child(4) {
					width: 12%;
				}
				&:nth-child(5) {
					width: 12%;
				}
				&:last-child {
					text-align: center;
				}
			}
		}
	}
}

.users-list .all-topic-table {
	flex-wrap: wrap;
	flex-direction: column;
}
.session-btn-width{
	width: fit-content !important;
}
.session-actions{
	width: fit-content !important;
}
.session-stack-gap{
	gap: 10px;
}