// Variables
@import '../../../styles/variables';
@import '../../../styles/colors';

.masked-icon {
	position: relative;

	&__levelOne {
		position: relative;
		z-index: 1;
	}

	&__levelTwo {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
    font-size: 2rem !important;
		z-index: 2;
	}
}

@import '~Styles/mixins';

.dashboard-page {
  max-width: 1140px;
  padding-top: 27px;

  .cart-wrap {
    display: block;

    @include media(md) {
      margin-bottom: 2rem !important;
    }
  }

  .ui {
    &.grid {
      > .row {
        &[class*="three column"] {
          &.pages-carts {
            > .column {
              @include media(lg) {
                width: 50% !important;

                &.cart-wrap {
                  margin-bottom: 2rem !important;

                  &:last-child {
                    margin-bottom: 0 !important;
                  }
                }
              }
              @include media {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6;

    span {
      display: block;
    }

    @include media {
      font-size: 18px;
    }
  }

  .actions {
    margin-bottom: 27px;
    text-align: right;

    .ui.button {
      width: auto;
      margin: 0;
      color: $blue;
      background-color: rgba(46, 91, 255, 0.2);
      font-weight: normal;

      &:hover {
        background-color: rgba(46, 91, 255, 0.3);
      }
    }
  }

  .bottom-cart {
    height: 100%;
    padding: 35px 25px;
    display: flex;
    background-color: #fff;

    .info {
      margin-left: 20px;
    }

    h3 {
      margin: 0;
    }

    span {
      color: $gray;
      line-height: 28px;
    }

    i {
      font-size: 3em;
    }

    a {
      margin-top: 7px;
      display: inline-block;
      color: $blue;
    }
  }
}
